export const languageHashValues= {
  "GLOBAL": ".12e2483cd9883fdb902b",
  "U4302_TRANSACTIONSDETAIL": ".83399c381b1d9f2c733c",
  "U4400_PAYMENT": ".7567b8cfd660591d4d12",
  "U5122_INVOICEMODIFICATIONAPPROVAL": ".e36adc13ffacb1293749",
  "U2117_PADAGREEMENTINVITATION": ".c22571a4e38a3fc6820a",
  "U2000_PAD": ".f109e2809c8ad67ec035",
  "U4402_INVOICEPORTALPAYMENT": ".eb3d3692c49cd964e98f",
  "U2000_BANKACCOUNTCREATION": ".a4ef1b83e52054287a7d",
  "U2011_LOGIN": ".95ab4964882818b6cc3d",
  "U2012_LOGINHELP": ".5f4a0e9367b7c66bc705",
  "U2014_SECURITY": ".c359fed7800ad316ad33",
  "U2086_EFTPENDINGPAYMENTS": ".54ab31bf277aa543b328",
  "U2087_EFTPAYMENTSNOTIFICATIONS": ".e3a9c38953980fef05bb",
  "U2110_ACCOUNTCREATION": ".00ac676ca8e09738cb45",
  "U2121_INDIVIDUALPROFILE": ".b42fd55803a523a4da1f",
  "U2131_ORGANIZATIONPROFILE": ".d8598c001ea2154efef6",
  "U2112_ERPBINDING": ".7e90fe2aab293e7dfa50",
  "U2113_SUPPLIERINVITATION": ".6be6430ccffa0534eeba",
  "U2118_RBBINDING": ".0dbd4c86551563f8dd1b",
  "U2132_AFFILIATION": ".4dfc86637b155ba8a92c",
  "U2133_BUSINESSLINKS": ".137f1fdcb1145769a740",
  "U2181": ".41f415dcdf139c7a573b",
  "U2211_SUBSCRIPTIONOFFER": ".a0a66aaccb779a0c7f84",
  "U2212_SUBSCRIPTIONFILTER": ".02aa0281ac42cddc8f0d",
  "U2213_SUBSCRIPTION": ".791841ff868560f18bcc",
  "U4111_CREDITCARDADDITION": ".e7b2ae2adf0c994eae15",
  "U4112_CREDITCARDDETAIL": ".f6212738421cb304f843",
  "U4121_BANKACCOUNTADDITION": ".e19356f4c459d795d214",
  "U4122_BANKACCOUNTDETAIL": ".762b7ae1ee23ed7dde27",
  "U4303_ANONYMOUSTRANSACTION": ".0f0c2c17f4413dfb8dc1",
  "U4401_PAYMENTGATEWAY": ".1f556e57d6d6bb430c14",
  "U4481_PROCESSINVOICEINSTALMENTPAYMENTS": ".d6c34e133e0f308dd8a0",
  "U5000_RECEIVABLE": ".37778e354ba7bcac2393",
  "U5101_STATEMENTOFACCOUNT": ".34e2c80f475f2e0faa62",
  "U5102_INVOICE": ".908f2e87feb0755608e8",
  "U5103_PADAGREEMENT": ".9ac05c6bd92f2721a862",
  "U5121_SENTINVOICE": ".c32d8a9dc5999a563e72",
  "U5124_PGRECEIVEDPAYMENT": ".194ff94175977b8e9b0e",
  "U5120_INVOICES": ".0bcfbd8bae362201cb2d",
  "U5123_PADAGREEMENTCONSOLE": ".e85befa3f4b7b6ae7d41",
  "U5132_PAYMENTRECONCILIATION": ".b99a25cc9760a2ee90c0",
  "U5133_BANKRECONCILIATION": ".4248e68172edd6e9dae2",
  "U6112_BUSINESSLINK": ".1d4cfcb482e91c5067bf",
  "U6113_SUPPLIERRECORD": ".7857a5a1d2ae1d8cd219",
  "U6211_PAYLOT": ".ab1fc9777ab3ffb9af22",
  "U6311_PURCHASE": ".9a6cee36843a26fe3486",
  "U2115_AFFILIATIONINVITATION": ".0f05e8505208ade38090",
  "U6100_PAYABLE": ".503173019489da5f97ae",
  "U6131_SENTPAYMENT": ".f478a3ec62f49d5940c6",
  "U6191_SENTPAYMENTREPORT": ".9df830de7bc1ceeae0e9",
  "U6221_SENTPAYMENT": ".bf8dbc66aa281fe7b150",
  "U6422_INTERACSENTPAYMENT": ".d2b17ac6ab4a8c2c5d51",
  "U5131_RECEIVEDPAYMENT": ".b92523d7b962a0c7e607",
  "U5191_RECEIVEDPAYMENTREPORT": ".df2cef3dc04ec2401f27",
  "U2001_HOME": ".a377c9d8accab5b938a9",
  "U2024_REPORTLOG": ".106ed83cf27e348b1a0b",
  "U2111_INVOICEPORTALBINDING": ".adf3f61d29b469c77697",
  "U2116_ANONYMOUSSUPPLIERINVITATION": ".e3f5cee25f6355aae77f",
  "U6111_SUPPLIERINVITATIONCONSOLE": ".5b4d6f983885513fd15b",
  "U6121_EFTLOT": ".92b9f414e942222f6c6a",
  "U6412_INTERACFILE": ".7737b9bdffbd8082bf36",
  "U6122_EFTAPPROVAL": ".d6bc0ca72f8888f608bd",
  "U6123_EFTAPPROVAL": ".b2aa2f46dc2fdeffc4bf",
  "U6123_EFTAPPROVALMOBILE": ".2a6736b9141a25378fe5",
  "U2012_LOGIN": ".2050e41668db63cc875e",
  "U2000_PAYMENT": ".55729d85af49edc658a8",
  "U4101_CREDITCARDBANKACCOUNTSUMMARY": ".29cc8aaa47d0d730991f",
  "U2000_PAYMENTMODEUPDATE": ".b7a0707a5550e46e4277",
  "U6114_TELUSBILLER": ".c1852184630a789e68ee",
  "U4403_CREDITCARDTERMINAL": ".d8334b6096ecc18e2624",
  "U4404_INTERACTERMINAL": ".3b50f768a0bce3d92bd3",
  "U2000_SAMPLECHEQUESELECTION": ".d8c4ad329844f77ec603",
  "U2023_TUTORIALVIDEO": ".24deef9e4c987ebc9c27",
  "U2134_ORGANIZATIONBILLING": ".6a8f42e6087ee7d7985d",
  "U6414_BANKACCOUNTWALLET": ".90639703ca80dec33ece",
  "U2022_INSTANTACCOUNTVERIFICATION": ".42ce203c02da7a5c9d46",
  "U4301_TRANSACTIONSSUMMARY": ".3c356a275b4286f3106e",
  "U5111_SENTINVOICESTRACKING": ".e1bfd838aaac28183b53",
  "U2000_ACCOUNTPERSONNALINFORMATIONS": ".01dac798abf3d70eaead",
  "U2000_INTRO": ".f461241f9b348cb3d6f2",
  "U2000_ELECTRONICSIGNATURE": ".7e107ce80716c07903f1",
  "U2000_VERIFYEMAIL": ".3b6da042155b8ed26e53",
  "U2200_SUBSCRIPTION": ".816d05e4cd2f16f05408",
  "U6200_PAYROLL": ".c344405d01ec90ff03a9",
  "U2000_VERIFYIDENTITY": ".46e47b614ef07619b02c",
  "U2000_ATTACHMENTVIEWER": ".f8f3bc7487de6f0398fb",
  "U2212_PLANLIST": ".55bd363ffaa54ae54efd",
  "U3002_TAXBILLSEARCHRESULT": ".beb7f11f594f6fd02cce"
}