import { AfterViewInit, Component, ElementRef, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { TranslateService } from '@ngx-translate/core';
import * as FileSaver from 'file-saver';

import { I18nService } from '../../../../i18n';
import { ModalService } from '../../../../modal';
import { FormQuestionBaseComponent } from '../../formquestion-base.component';
import { FormFileUploadQuestion } from '../../formquestion-fileupload';

@Component({
    selector: 'form-fileupload-bootstrap',
    templateUrl: './fileupload.component.html',
    styleUrls: ['./fileupload.component.scss'],
})
export class FormFileUploadBootstrapComponent extends FormQuestionBaseComponent implements AfterViewInit {
    @Input() override question: FormFileUploadQuestion;
    @Input() override form: UntypedFormGroup;

    input: HTMLInputElement;
    label: HTMLElement;

    sysViewUploadedDocument: string;
    sysDownloadUploadedDocument: string;

    constructor(i18nService: I18nService, private el: ElementRef, private translateService: TranslateService, private modalService: ModalService) {
        super(i18nService);

        this.translateService.setTranslation(
            'fr',
            {
                sysWarning: 'Attention',
                sysMessageUploadLimit: 'Impossible de choisir un fichier excédant la limite permise. ({{sizeLimit}} Ko)',
                sysViewUploadedDocument: 'Consulter le document téléversé',
                sysDownloadUploadedDocument: 'Télécharger le document téléversé',
            },
            true,
        );
        this.translateService.setTranslation(
            'en',
            {
                sysWarning: 'Warning',
                sysMessageUploadLimit: 'Can not choose a file exceeding the legal limit. ({{sizeLimit}} Kb)',
                sysViewUploadedDocument: 'View the uploaded document',
                sysDownloadUploadedDocument: 'Download the uploaded document',
            },
            true,
        );
        const inputs = document.querySelectorAll('.inputfile');
        Array.prototype.forEach.call(inputs, input => {
            /* Must be empty */
        });

        this.sysViewUploadedDocument = this.translateService.instant('sysViewUploadedDocument');
        this.sysDownloadUploadedDocument = this.translateService.instant('sysDownloadUploadedDocument');
    }

    ngAfterViewInit() {
        if (this.question.onAfterViewInit) {
            this.question.onAfterViewInit(this.el.nativeElement);
        }
        super.afterViewInit(this, this.el);

        this.input = (this.el.nativeElement as HTMLElement).getElementsByClassName('form-control')[0] as HTMLInputElement;
        this.label = (this.el.nativeElement as HTMLElement).getElementsByClassName('upload-false-label')[0] as HTMLElement;

        const labelVal = this.label.innerHTML;
        const myThis = this;

        this.input.addEventListener('change', e => {
            if (this.input.files[0] != null) {
                if (myThis.question.sizeLimit !== -1 && this.input.files[0].size > myThis.question.sizeLimit * 1024) {
                    myThis.modalService.alert({
                        title: myThis.translateService.instant('sysWarning'),
                        message: myThis.translateService.instant('sysMessageUploadLimit', {
                            sizeLimit: myThis.question.sizeLimit.toString(),
                        }),
                    });
                    this.input.value = '';
                }
            } else {
                this.input.value = '';
            }

            if (myThis.question.onChange != null) {
                myThis.question.onChange(e, myThis.question);
            }

            let fileName = '';
            if (this.input.files && this.input.files.length > 1) {
                fileName = (this.input.getAttribute('data-multiple-caption') || '').replace('{count}', this.input.files.length as any);
            } else {
                fileName = e.target['value'].split('\\').pop();
            }
            if (fileName) {
                // this.label.querySelector('span').innerHTML = fileName;
                this.question.fileName = fileName;
            } else {
                this.label.innerHTML = labelVal;
            }
        });

        // Firefox bug fix
        this.input.addEventListener('focus', () => {
            this.input.classList.add('has-focus');
        });
        this.input.addEventListener('blur', () => {
            this.input.classList.remove('has-focus');
        });
    }

    onFileChange(event: Event) {
        if ((event.target as HTMLInputElement).files[0] == null) {
            this.question.formControlRef.setValue(null);
            this.question.data = null;
        } else {
            if (this.question.base64Data) {
                this.question.formControlRef.setValue((event.target as HTMLInputElement).files[0].name);
                const reader: FileReader = new FileReader();
                reader.onload = (readerEvent: Event) => {
                    this.question.data = (readerEvent.target as FileReader).result;
                };
                reader.readAsDataURL((event.target as HTMLInputElement).files[0]);
            } else {
                const formData = new FormData();
                formData.append('file', (event.target as HTMLInputElement).files[0]);
                this.question.formControlRef.setValue(formData);
                this.question.data = formData;
            }
        }
    }

    onReset() {
        this.input.value = '';
        this.label.innerHTML = '';
    }

    onChangeInternal(event: Event, question: FormFileUploadQuestion) {
        if (this.question.onChange) {
            this.question.onChange(event, this.question);
        }
    }

    viewPreviewClick() {
        const win = window.open();
        win.document.write(`
            <iframe src="${this.question.data}" frameborder="0" style="border:0; width:100%; height:100%;" allowfullscreen>
            </iframe>
        `);
        win.document.close();
    }

    downloadPreviewClick() {
        const fileName = this.question.value;
        FileSaver.saveAs(this.question.data, fileName);
    }
}
