import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

/*
 * Temporary currency formatter to avoid CA$500.00 when using the Angular one
 */
@Pipe({ name: 'currency' })
export class CustomCurrencyPipe implements PipeTransform {
    constructor(private currencyPipe: CurrencyPipe) {}

    // The digits default is required on old Android devices.
    transform(value: number, currencyCode = 'CAD', symbolDisplay = 'symbol', digits = '1.2-2'): string {
        if (value == null) {
            return '';
        }
        const formattedValue = this.currencyPipe.transform(value, currencyCode, symbolDisplay, digits);

        if (formattedValue == null) {
            return '';
        }

        return formattedValue;
    }
}
